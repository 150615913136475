import React from 'react';
import styles from './instructions.module.css';
import whoLogo from '../img/who-top.png';
import globalGivingLogo from '../img/global-giving-top.png';
import directReliefLogo from '../img/direct-relief-top.png';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

function Instructions() {

    const worldHealthOrganizationButtonClickHandler = () => {
        ReactGA.event({
            category: 'World Health Organization Donate Button',
            action: 'Clicked World Health Organization Donate Button'
        });
    }

    const globalGivingButtonClickHandler = () => {
        ReactGA.event({
            category: 'Global Giving Donate Button',
            action: 'Clicked Global Giving Donate Button'
        });
    }

    const directReliefButtonClickHandler = () => {
        ReactGA.event({
            category: 'Direct Relief Donate Button',
            action: 'Clicked Direct Relief Donate Button'
        });
    }

    const seeAllFundsButtonClickHandler = () => {
        ReactGA.event({
            category: 'See All Funds button',
            action: 'Clicked See All Funds button'
        });
    }

    return  (
        <section className={styles['instructions']} id="instructions">
            <div className={styles['copy']}>
                <h3>Official funds</h3>
                <h1>Help the fight without leaving your home.</h1>
                <hr />
                <p>Choose one (or more) of the official funds and donate. Every donation counts and helps the fight against COVID-19.</p>
            </div>
            <div className={styles['funds']}>
                <div className={styles['fund-spacer']}>
                    <div><img src={whoLogo} alt="World Health Organization" className={styles['top']} /></div>
                    <div className={styles['fund1']}>
                        <h3>COVID-19 Solidarity Response Fund for WHO</h3>
                        <a href="https://covid19responsefund.org/">
                            <button onClick={() => {
                                worldHealthOrganizationButtonClickHandler();
                            }}>
                            Donate now
                            </button>
                        </a>
                        <p>Clicked <strong>36</strong> times!</p>
                    </div>
                </div>
                <div className={styles['fund-spacer']}>
                    <div><img src={globalGivingLogo} alt="Global Giving" className={styles['top']} /></div>
                    <div className={styles['fund1']}>
                        <h3>Coronavirus Relief Fund - stop the virus's spread</h3>
                        <a href="https://www.globalgiving.org/projects/coronavirus-relief-fund/">
                            <button onClick={() => {
                                globalGivingButtonClickHandler();
                            }}>
                            Donate now
                            </button>
                        </a>
                        <p>Clicked <strong>12</strong> times!</p>
                    </div>
                </div>
                <div className={styles['fund-spacer']}>
                    <div><img src={directReliefLogo} alt="Direct Relief" className={styles['top']} /></div>
                    <div className={styles['fund1']}>
                        <h3>COVID-19 Relief - Providing assistance</h3>
                        <a href="https://secure.directrelief.org/site/Donation2?idb=75668829&df_id=2924&mfc_pref=T&2924.donation=form1&2924_donation=form1">
                            <button onClick={() => {
                                directReliefButtonClickHandler();
                            }}>
                            Donate now
                            </button>
                        </a>
                        <p>Clicked <strong>8</strong> times!</p>
                    </div>
                </div>
                <div className={styles['fund-spacer-main']}>
                    <div className={styles['fund-main-button']}>
                        <Link to='/funds'>
                            <button onClick={() => {
                                seeAllFundsButtonClickHandler();
                            }}>
                            See all funds
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Instructions;