import React from 'react';
import styles from './gallery.module.css';
import gallery from '../img/gallery.png';
import ReactGA from 'react-ga';

function Gallery() {

    const galleryImageClickHandler = () => {
        ReactGA.event({
            category: 'Gallery Image',
            action: 'Clicked the Gallery image'
        });
    }

    const galleryButtonClickHandler = () => {
        ReactGA.event({
            category: 'Gallery Button',
            action: 'Clicked the Gallery button'
        });
    }

    return (
        <section className={styles['gallery']}>
            <h1>Stand alone, together.</h1>
            <hr />
            <p>
                Donate to one (or more) of the official funds fighting COVID-19 and post a picture of yourself with the hashtag #ISOLATE2DONATE on instagram, twitter or facebook so others can join the movement.
            </p>
            <div onClick={galleryImageClickHandler}>
                <a href="https://www.instagram.com/isolate2donate/?igshid=1bau9vldvthpg">
                    <img src={gallery} alt="Gallery" />
                </a>
            </div>
            <a href="https://www.instagram.com/isolate2donate/?igshid=1bau9vldvthpg">
                <button className={styles['gallery-button']} onClick={galleryButtonClickHandler}>Go to Instagram</button>
            </a>
        </section>
    )
}

export default Gallery;