import React from 'react';
import styles from './contact.module.css';
import ReactGA from 'react-ga';

function Contact() {

    const contactUsButtonClickHandler = () => {
        ReactGA.event({
            category: 'Contact Us Button',
            action: 'Clicked Contact Us Button'
        });
    }

    return (
        <section className={styles['contact-us']}>
            <h1>What other official funds should we include on our list?</h1>
            <hr />
            <p>Let us know by email if there are any more official funds we could include in our list of options.</p>
            <a href="mailto:contact@isolate2donate.com" target="_blank" rel="noopener noreferrer">
                <button onClick={contactUsButtonClickHandler}>Contact Us</button>
            </a>
        </section>
    )
}

export default Contact;