import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import './app.css';
import ReactGA from 'react-ga';
import Home from './components/Home';
import Funds from './components/Funds';

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-161523181-1", {
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    });
    ReactGA.ga('send', 'pageview', location.pathname);
  }, [location]);

  return (
    <>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/funds' component={Funds} />
      </Switch>
    </>
  );
}

export default App;
