import React from 'react';
import downIcon from '../img/down-icon.png';
import { Link } from 'react-scroll';
import ReactGA from 'react-ga';
import styles from './findoutmore.module.css'

function FindOutMore() {

    const heroFindOutMoreButtonClickHandler = () => {
        ReactGA.event({
            category: 'Hero Find Out More button',
            action: 'Clicked the Hero Find Out More button'
        });
    }

    return (
        <div className={styles['findoutmore']}>
            <p>Find out more</p>
            <Link to="about" smooth={true} offset={-70} duration={500}>
                <button onClick={heroFindOutMoreButtonClickHandler}>
                    <img src={downIcon} alt="Gallery" className={[styles.animated, styles.infinite, styles.delay2s, styles.bounce].join(' ')} />
                </button>
            </Link>
        </div>
    )
}

export default FindOutMore;