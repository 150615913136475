import React from 'react';
import styles from './footer.module.css';
import logo from '../img/logo-footer.png';
import instagramFooter from '../img/instagram.svg';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';


function Footer() {

    const instagramFooterIconClickHandler = () => {
        ReactGA.event({
            category: 'Instagram Footer Icon',
            action: 'Clicked Instagram Footer Icon'
        });
    }

    return (
        <footer>
            <div className={styles['footer-container']}>
                <div>
                    <Link to='/'>
                        <img src={logo} className={styles['footer-logo']} alt="Isolate2Donate" />
                    </Link>
                </div>
                <div>
                <a href="https://www.instagram.com/isolate2donate/?igshid=1bau9vldvthpg" onClick={instagramFooterIconClickHandler}>
                        <img src={instagramFooter} className={styles['instagram-footer-logo']} alt="Instagram" />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;