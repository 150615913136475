import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import About from '../components/About';
import Gallery from '../components/Gallery';
import Instructions from '../components/Instructions';
import Contact from '../components/Contact';

function Home() {
  return (
    <div className="app">
      <Header />
      <Hero />
      <About />
      <Gallery />
      <Instructions />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;