import React from 'react';
import { Link } from 'react-router-dom';
import styles from './about.module.css';
import ReactGA from 'react-ga';

function About() {

    const aboutDonateButtonClickHandler = () => {
        ReactGA.event({
            category: 'About Donate Button',
            action: 'Clicked the About Donate button'
        });
    }

    return (
        <section className={styles['about']} id="about">
            <h1>What's ISOLATE<span className={styles['two']}>2</span>DONATE about?</h1>
            <hr />
            <h3>
                Staying home and staying safe is part of the fight.
                Now you can also choose to donate and support one of the official funds that are fighting COVID-19.
                ISOLATE2DONATE does not collect any money, we simply direct you towards one of the official funds so you can make your donation there.
            </h3>
            <Link to='/funds'>
                <button onClick={aboutDonateButtonClickHandler}>Donate</button>
            </Link>
        </section>
    )
}

export default About;