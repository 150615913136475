import React from 'react';
import { Link } from 'react-router-dom';
import styles from './hero.module.css';
import ReactGA from 'react-ga';
import FindOutMore from './FindOutMore';

function Hero() {

    const heroDonateButtonClickHandler = () => {
        ReactGA.event({
            category: 'Hero Donate Button',
            action: 'Clicked the Hero Donate button'
        });
    }

    return (
        <section className={styles['hero']}>
            <div className={styles['hero-container']}>
            <div className={styles['join-the-cause-container']}>
                <span>Stay at home</span>
                <div className={styles['join-the-cause-red']}></div>
            </div>
            <h1>Isolate<span className={styles['two']}>2</span>Donate</h1>
            <h2>Let's stand alone, together. Join the #ISOLATE2DONATE movement and help one of the official funds that are fighting COVID-19.</h2>
            <Link to='/funds'>
                <button onClick={heroDonateButtonClickHandler}>Donate</button>
            </Link>
            </div>
            <FindOutMore />
        </section>
    )
}

export default Hero;