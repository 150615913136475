import React, { useEffect } from 'react';
import styles from './funds.module.css';
import { Link } from 'react-scroll';
import FundsHeader from './FundsHeader';
import FundsFooter from './FundsFooter';
import GlobalFund from './GlobalFund';
import UKFund from './UKFund';
import EUFund from './EUFund';
import USFund from './USFund';
import APACFund from './APACFund';
import LATAMFund from './LATAMFund';

function Home() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

  return (
    <>
    <div className={styles['funds']}>
		<div className={styles['container']}>
			<div className={styles['wrapper']}>
				<FundsHeader />
				<div className={styles['instructions']}>
					<h1>How can you help</h1>
					<hr />
					<h2>- You can donate to one of the funds below</h2>
					<h2>- <strong>OR</strong> Post a picture on <a href="https://www.instagram.com/isolate2donate/?igshid=1bau9vldvthpg">Instagram</a> with the hashtag #ISOLATE2DONATE to spread the word</h2>
					<h2>- <strong>OR</strong> Follow @isolate2donate on <a href="https://www.instagram.com/isolate2donate/?igshid=1bau9vldvthpg">Instagram</a></h2>
				</div>
				<div className={styles['nav']}>
					<h1>Funds <span>::</span></h1>
					<hr />
					<div className={styles['nav-menu']}>
						<ul>
							<Link to="global" smooth={true} offset={-70} duration={500}><li>Global</li></Link>
							<Link to="uk" smooth={true} offset={-70} duration={500}><li>UK</li></Link>
							<Link to="eu" smooth={true} offset={-70} duration={500}><li>EU</li></Link>
							<Link to="us" smooth={true} offset={-70} duration={500}><li>US</li></Link>
							<Link to="apac" smooth={true} offset={-70} duration={500}><li>APAC</li></Link>
							<Link to="latam" smooth={true} offset={-70} duration={500}><li>LATAM</li></Link>
						</ul>
					</div>
				</div>

				<div className={styles['location-wrapper']} id="global">
					<h1>Global</h1>
					<GlobalFund />
				</div>

				<div className={styles['location-wrapper']} id="uk">
					<h1>UK</h1>
					<UKFund />
				</div>

				<div className={styles['location-wrapper']} id="eu">
					<h1>EU</h1>
					<EUFund />
				</div>

				<div className={styles['location-wrapper']} id="us">
					<h1>US</h1>
					<USFund />
				</div>

				<div className={styles['location-wrapper']} id="apac">
					<h1>APAC</h1>
					<APACFund />
				</div>

				<div className={styles['location-wrapper']} id="latam">
					<h1>LATAM</h1>
					<LATAMFund />
				</div>

				<FundsFooter />
			</div>
		</div>
	</div>
	
    </>
  );
}

export default Home;