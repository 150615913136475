import React from 'react';
import styles from './funds.module.css';
import USFundData from '../data/us-funds.json';
import ReactGA from 'react-ga';

function USFund() {
    return (
        <>
            {USFundData.map((fundDetail, index) => {
                return <div className={styles['table']} key={fundDetail.id}>
                    <div className={styles['row']}>
                        <div className={styles['cell']}>
                            <div className={styles['counter']}>
                                <p><strong>{fundDetail.clicks}</strong></p>
                            </div>
                        </div>
                        <div className={styles['cell']}>
                            {fundDetail.title}
                        </div>
                        <div className={styles['cell']}>
                            {fundDetail.description}
                        </div>
                        <div className={styles['cell']}>
                            {fundDetail.location}
                        </div>
                        <div className={styles['cell']}>
                            <button onClick={ fundDetail.googleTracking = () => {ReactGA.event({ category: `${fundDetail.category}`, action: `${fundDetail.action}` }); console.log(fundDetail.googleTracking)} }>
                                <a href={fundDetail.url} target="_blank" rel="noopener noreferrer">
                                    {fundDetail.cta}
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            })}
        </>
    )
}

export default USFund;