import React from 'react';
import styles from './fundsheader.module.css';
import logo from '../img/logo-footer.png';
import { instagramHeaderIconClickHandler } from '../utils/handlers';
import { Link } from 'react-router-dom';
import instagram from '../img/instagram.svg';

function FundsHeader() {
    return (
        <header className={styles['header']}>
            <div>
                <Link to='/'>
                    <img src={logo} className={styles['header-logo']} alt="Isolate2Donate" />
                </Link>
            </div>
            <div className={styles['header-top-instagram']}>
                <a href="https://www.instagram.com/isolate2donate/?igshid=1bau9vldvthpg" onClick={instagramHeaderIconClickHandler}>
                    <div><span className={styles['header-top-instagram-text']}>Follow us on</span><img src={instagram} className={styles['header-instagram']} alt="Instagram" /></div>
                </a>
            </div>
        </header>    
    )
}

export default FundsHeader;