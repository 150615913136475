import React from 'react';
import styles from './header.module.css';
import logo from '../img/logo.png';
import instagram from '../img/instagram.svg';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

function Header() {

    const instagramHeaderIconClickHandler = () => {
        ReactGA.event({
            category: 'Instagram Header Icon',
            action: 'Clicked Instagram Header Icon'
        });
    }

    return (
        <header className={styles['header']}>
            <div>
                <Link to='/'>
                    <img src={logo} className={styles['header-logo']} alt="Isolate2Donate" />
                </Link>
            </div>
            <div className={styles['header-top-instagram']}>
                <a href="https://www.instagram.com/isolate2donate/?igshid=1bau9vldvthpg" onClick={instagramHeaderIconClickHandler}>
                    <div><span className={styles['header-top-instagram-text']}>Follow us on</span><img src={instagram} className={styles['header-instagram']} alt="Instagram" /></div>
                </a>
            </div>
        </header>
    )
}

export default Header;